import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"

export default function MultiColumnCTA({
    global,
    pathPrefix,
    customClass = "",
    right_content,
    left_content,
    left_post,
    right_post,
    locale,
    leftColumnFilterUrl = `jobs/#/`,
    rightColumnFilterUrl = `business-solutions/`,
    sectionId = ""
}) {

    return (
        <section className={`${styles.root} ${customClass} py-1`} id={`${sectionId}`}>
            <Row className="m-0">
                <Col md={12} className="left-column p-md-5 p-4">
                    {left_content ?
                        <div className="mb-4">
                            <SourceFlowText global={global} path={`${pathPrefix}.left_content`} type="html">
                                {left_content}
                            </SourceFlowText>
                        </div> : null}
                    {left_post.length > 1 && (
                        <div className="d-flex flex-wrap">
                            {left_post?.map((post, index) => (
                                <Card className="bg-transparent border-0 me-2 mb-2" key={index}>
                                    {post?.title == "UI & UX" ||
                                        post?.title == "Project/Program Management, Change Managment & Business Analysis" ||
                                        post?.title == "Artificial Intelligence" ||
                                        post?.title == "Change and Transformation" ||
                                        post?.title == "Business Analysis" ||
                                        post?.title == "Networking and Infrastructure" ||
                                        post?.title == "Testing" ||
                                        post?.title == "ERP & Customer Relationship  Management" ||
                                        post?.title == "Business Analysis, Change and Transformation"
                                        ?
                                        <div className="rounded-5 py-2 px-4 with-no-link">
                                            {post?.title ? post.title : null}
                                            {/* {post?.name ? post.name : null} */}
                                            <br></br>
                                        </div>
                                        :
                                        <Link key={index} href={`/${locale ? locale : ""}${leftColumnFilterUrl}${post.url_slug}/`}>
                                            <a className="rounded-5 py-2 px-4">
                                                {post?.title ? post.title : null}
                                                {/* {post?.name ? post.name : null} */}
                                                <br></br>
                                            </a>
                                        </Link>}
                                </Card>
                            ))}
                        </div>
                    )}

                </Col>
                {/* <Col md={6} className="right-column p-md-5 p-4">
                    {right_content ?
                        <div className="mb-4">
                            <SourceFlowText global={global} path={`${pathPrefix}.right_content`} type="html">
                                {right_content}
                            </SourceFlowText>
                        </div> : null}
                    {right_post.length > 1 && (
                        <div className="d-flex flex-wrap">
                            {right_post?.map((post, index) => (
                                <Card className="bg-transparent border-0 me-2 mb-2">
                                    <Link key={index} href={`/${locale ? locale : ""}${rightColumnFilterUrl}${post.url_slug}/`}>
                                        <a className="rounded-5 py-2 px-4">
                                            {post.title}
                                        </a>
                                    </Link>
                                </Card>
                            ))}
                        </div>
                    )}
                </Col> */}
            </Row>
        </section>
    )
}