import { Global, Collection } from '@sourceflow-uk/sourceflow-sdk';
import SeoHead from '../components/SeoHead';
import LatestAwards from '../components/LatestAwards';
import LatestNews from '../components/LatestNews';
import MainBanner from '../components/MainBanner';
import LatestJobs from '../components/LatestJobs';
import ImageWithVideoBanner from '../components/ImageWithVideoBanner';
import MultiColumnCTA from '../components/MultiColumnCTA';
import LatestTestimonials from '../components/LatestTestimonials';
import AllSectors from '../components/AllSectors';
import {
	Container,
} from 'reactstrap';

export default function Home({
	global,
	all_awards,
	all_news,
	// all_jobs,
	// all_solutions,
	all_sectors,
	all_candidate_testimonials

}) {
	return (
		<>
			<SeoHead />
			<MainBanner
				global={global}
				pathPrefix={`homepage.main_banner`}
				customClass={``}
				content={
					<>
						<h1>
							Project engineering and technical recruitment specialists - Driving the future of engineering.
						</h1>
						<p>With ambitious people at our heart, we’re driven by our ability to facilitate positive change. Connecting engineering specialists with the very best and brightest talent, we go the extra mile to create bespoke solutions and powerful collaborations.</p>
					</>

				}
				imageURL1={`/site-assets/images/home-main-banner.webp`}
				imageALT1={`Main Banner`}
				imageURL2={`/site-assets/images/new-main-banner-overlay.jpg`}
				imageALT2={`Overlay Main Banner`}
				jobSearch={true}
				pageAnchor={`all-sectors`}
				locale={``}
			/>
			<AllSectors
				global={global}
				pathPrefix={`homepage.all_sectors`}
				sectionId={`all-sectors`}
				customClass={`py-5 blueGradientBg`}
				content={
					<>
						<h2>Our Sectors</h2>
						<p>Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
					</>
				}
				post={all_sectors}
			/>
			<ImageWithVideoBanner
				global={global}
				pathPrefix={`homepage.image_with_video_banner`}
				sectionId={`what-we-do`}
				customClass={`py-5`}
				content={<>
					<h2>Allies in Engineering</h2>
					<p>We’re dedicated to people and the difference we make in our industry.</p>
				</>
				}
				buttonTitle={`Learn More About What We Do`}
				buttonLink={`/`}
				videoLink={`https://www.youtube.com/watch?v=799qeziBS9s`}
				bannerImage={``}
				imageURL={`/site-assets/images/blog-hero-image.png`}
				imageALT={`It's what we do`}
				videoGalleryClass={`WhatWeDo`}
				pageAnchor={`more-info-of-us`}
			/>
			{/* <LatestJobs
				global={global}
				pathPrefix={`homepage.latest_jobs`}
				customClass={`py-5`}
				post={all_jobs}
				content={<>
					<h2>Latest Aerospace & Defence Jobs</h2>
					<p>Find the best Aerospace & Defence career opportunities</p>
				</>
				}
				buttonTitle={`View All Jobs`}
				buttonLink={`/jobs/`}
				swiperID={`latestJobsSwiper`}
				locale={``}
			/> */}
			{/* <MultiColumnCTA
				global={global}
				pathPrefix={`homepage.multi_column_cta`}
				sectionId={`more-info-of-us`}
				customClass={`py-5`}
				left_content={
					<>
						<h2>Market's We Recruit</h2>
					</>
				}
				right_content={
					<>
						<h2>Business Solutions</h2>
					</>
				}
				left_post={all_sectors}
				right_post={all_solutions}
				leftColumnFilterUrl={`sectors/`}
			/> */}
			<LatestAwards
				global={global}
				pathPrefix={`homepage.latest_awards`}
				customClass={`pt-5 text-center`}
				content={
					<>
						<h2>Trusted by Leading Businesses</h2>
					</>
				}
				post={all_awards}
				buttonLink={``}
				buttonTitle={``}
				swiperID={`latestAwardsSwiper`}
			/>
			<LatestTestimonials
				global={global}
				pathPrefix={`homepage.latest_testimonials`}
				customClass={`py-5`}
				content={
					<>
						<h2>Candidate's Testimonials</h2>
					</>
				}
				post={all_candidate_testimonials}
				swiperID={`latestTestimonialsSwiper`}
			/>

			<LatestNews
				global={global}
				pathPrefix={`homepage.latest_news`}
				customClass={`py-5 grayGradientBg`}
				post={all_news}
				content={
					<>
						<h2>Latest News, Events & Insights</h2>
						<p>Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
					</>
				}
				buttonLink={`/blog/`}
				buttonTitle={`View All Insights`}
			/>
		</>
	)
}

export async function getStaticProps({ params }) {
	const global = (await Global()).toJson();
	const awards = await Collection('awards_industry_recognition');
	// const solutions = await Collection('solutions');
	const sectors = (await Collection('sectors'))
		.filter((cat) => cat.solutions?.en === "Business Solutions")
		.filter(function (post) {
			return (
				post.region?.en.includes("United Kingdom")
			);
		})

	const news = (await Collection('news'))
	// .filter(function (post) {
	// 	return (
	// 		post.country?.en == "United Kingdom" || post.country?.en == "All"
	// 	);
	// })
	const candidate_testimonials = await Collection('candidate_testimonials');

	const all_candidate_testimonials = candidate_testimonials.orderBy('order').getItems();
	// const all_jobs = (await Jobs())
	// 	.filter(function (job) {
	// 		return (
	// 			job.categories
	// 				// Pulls Sectors only
	// 				.find((cat) => cat.id === "82e8528a-fa52-4429-8e92-ecf1f928f73c" && cat.values.find((x) => x.name === "United Kingdom"))
	// 		);
	// 	})
	// 	.filter(function (job) {
	// 		return (
	// 			(new Date(job?.expires_at)) >= (new Date())
	// 		)
	// 	})
	// 	.getItems().slice(0, 8);
	// const all_solutions = solutions.orderBy('order').getItems();
	const all_sectors = sectors.orderBy('order').getItems();
	const all_awards = awards.orderBy('order').getItems().filter(i => i.show_on.includes('Home'));
	const all_news = news.orderBy('date').getItems().slice(0, 8);
	return {
		props: {
			global,
			all_awards,
			all_news,
			// all_jobs,
			// all_solutions,
			all_sectors,
			all_candidate_testimonials
		}
	};
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflowtext